import { mapHelper } from "@/utils/common.js";
// 课程筛选
const classMonth = [
  {
    value: 1,
    label: "1月",
  },
  {
    value: 2,
    label: "2月",
  },
  {
    value: 3,
    label: "3月",
  },
  {
    value: 4,
    label: "4月",
  },
  {
    value: 5,
    label: "5月",
  },
  {
    value: 6,
    label: "6月",
  },
  {
    value: 7,
    label: "7月",
  },
  {
    value: 8,
    label: "8月",
  },
  {
    value: 9,
    label: "9月",
  },
  {
    value: 10,
    label: "10月",
  },
  {
    value: 11,
    label: "11月",
  },
  {
    value: 12,
    label: "12月",
  },
];
// 培训形式
const trainType = [
  {
    value: 1,
    label: "开放式党课",
  },
  {
    value: 2,
    label: "集中学习",
  },
];
const { map: trainTypeMap, setOps: trainTypeOps } = mapHelper.setMap(trainType);

export { classMonth, trainType, trainTypeMap };
