<template>
  <div class="divBox">
    <v-list
      ref="list"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="重置" @click="reset"></v-button>
        <v-button text="新增" @click="handleAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-select
          clearable
          placeholder="请选择年份"
          :options="classYear"
          v-model="searchParam.year"
          @change="$refs.list.search()"
          label="年份"
        />
        <v-input
          label="搜索"
          v-model="searchParam.name"
          placeholder="请输入课程名称"
        />
        <v-select
          clearable
          placeholder="请选择月份"
          :options="classMonth"
          v-model="searchParam.month"
          @change="$refs.list.search()"
          label="课程筛选"
          :disabled="searchParam.year === ''"
        />
        <v-input
          label="培训形式"
          v-model="searchParam.trainType"
          placeholder="请输入培训形式"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="handleEdit(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
    <v-dialog
      :title="dialogTitle"
      v-model="dialogFormVisible"
      sureTxt="保存"
      confirm="saveEdit"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="课程名称">
          <v-input
            clearable
            class="filter-item"
            v-model="temp.name"
            placeholder="请输入课程名称"
          ></v-input>
        </el-form-item>
        <el-form-item label="培训形式">
          <v-input
            clearable
            class="filter-item"
            v-model="temp.trainType"
            placeholder="请输入培训形式"
          ></v-input>
        </el-form-item>

        <el-form-item label="课程日期">
          <v-date v-model="temp.openDate"></v-date>
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  getCpbCourseListUrl,
  saveCpbCourseInfoUrl,
  getCpbCourseInfoByIdUrl,
  deleteCpbCourseUrl,
} from "./api.js";
import { classMonth, trainType, trainTypeMap } from "./map.js";

export default {
  name: "classScheduleCard",
  data() {
    return {
      dialogTitle: null,
      dialogFormVisible: false,
      temp: {
        tenantId: undefined, // 租房id
        optUser: undefined, // 用户id
        name: "",
        trainType: "",
        openDate: "",
        id: "",
      },
      classYear: [],
      classMonth,
      trainType,
      searchParam: {
        name: "",
        trainType: "",
        // openDate: "",
        month: "",
        year: "",
      },

      tableUrl: getCpbCourseListUrl,
      headers: [
        {
          prop: "name",
          label: "课程名称",
        },
        {
          prop: "openDate",
          label: "课程日期",
        },
        {
          prop: "trainType",
          label: "培训形式",
        },
      ],
    };
  },
  created() {
    this.getYearList();
  },
  computed: {},
  methods: {
    getYearList() {
      var date = new Date();
      var nowYear = date.getFullYear();
      let list = [
        { value: nowYear - 5, label: String(nowYear - 5) },
        { value: nowYear - 4, label: String(nowYear - 4) },
        { value: nowYear - 3, label: String(nowYear - 3) },
        { value: nowYear - 2, label: String(nowYear - 2) },
        { value: nowYear - 1, label: String(nowYear - 1) },
        { value: nowYear, label: String(nowYear) },
        { value: nowYear + 1, label: String(nowYear + 1) },
        { value: nowYear + 2, label: String(nowYear + 2) },
        { value: nowYear + 3, label: String(nowYear + 3) },
        { value: nowYear + 4, label: String(nowYear + 4) },
        { value: nowYear + 5, label: String(nowYear + 5) },
        { value: nowYear + 7, label: String(nowYear + 6) },
      ];
      this.classYear = list;
    },
    // 重置按钮
    reset() {
      this.searchParam.name = "";
      this.searchParam.openDate = "";
      this.searchParam.trainType = "";
    },
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: item.id,
        };
        this.$axios
          .post(`${deleteCpbCourseUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },

    handleAdd() {
      this.dialogTitle = "添加";
      this.dialogFormVisible = true;
      this.temp.name = null;
      this.temp.trainType = null;
      this.temp.openDate = null;
      this.temp.id = null;
    },
    handleEdit(data) {
      this.dialogTitle = "编辑";
      this.dialogFormVisible = true;
      this.temp.id = data.id;
      this.temp.name = data.name;
      this.temp.trainType = data.trainType;
      this.temp.openDate = data.openDate;
    },
    dateFormat(time) {
      let year = time.getFullYear();
      let month =
        time.getMonth() + 1 >= 10
          ? time.getMonth() + 1
          : "0" + (time.getMonth() + 1);
      let day = time.getDate() >= 10 ? time.getDate() : "0" + time.getDate();
      console.log(`${year}-${month}-${day}`);
      return `${year}-${month}-${day}`;
    },
    saveEdit() {
      if (!this.temp.name || this.temp.name == "") {
        this.$message.error("请选择课程名称");
        return;
      }
      if (!this.temp.trainType || this.temp.trainType == "") {
        this.$message.error("请选择培训形式");
        return;
      }
      if (!this.temp.openDate || this.temp.openDate == "") {
        this.$message.error("请输入课程日期");
        return;
      }
      if (!this.temp.optUser) {
        this.temp.optUser = this.$store.state.app.userInfo.userId;
      }
      if (!this.temp.tenantId) {
        this.temp.tenantId = this.$store.state.app.userInfo.tenantId;
      }
      this.temp.openDate = this.dateFormat(this.temp.openDate);
      this.$axios.post(`${saveCpbCourseInfoUrl}`, this.temp).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功！");
          this.$refs.list.search();
        } else {
        }
      });
      this.dialogFormVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
