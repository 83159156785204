//获取课程表列表列表
const getCpbCourseListUrl = `/gateway/hc-govern/cpbCourse/getCpbCourseList`;
// 新增/编辑课程
const saveCpbCourseInfoUrl = `/gateway/hc-govern/cpbCourse/saveCpbCourseInfo`;
// 课程详情查询
const getCpbCourseInfoByIdUrl = `/gateway/hc-govern/cpbCourse/getCpbCourseInfoById`;
// 删除课程
const deleteCpbCourseUrl = `/gateway/hc-govern/cpbCourse/deleteCpbCourse`;

export {
  getCpbCourseListUrl,
  saveCpbCourseInfoUrl,
  getCpbCourseInfoByIdUrl,
  deleteCpbCourseUrl,
};
